<template>
    <div class="form full-form auth-cover">
        <div class="form-container">
            <div class="form-form">
                <div class="form-form-wrap">
                    <div class="form-container">
                        <div class="form-content">
                            <h1 class="">
                                Přihlásit se <router-link to="/login"><span class="brand-name">Volistore</span></router-link>
                            </h1>
                            <p class="signup-link">Pokud chcete pokračovat, <router-link to="/login">přihlaste se</router-link> do svého účtu.</p>
                            <b-form class="text-left" @submit.prevent="login">
                                <div class="form">
                                    <div id="username-field" class="field-wrapper input">
                                        <svg
                                            xmlns="http://www.w3.org/2000/svg"
                                            width="24"
                                            height="24"
                                            viewBox="0 0 24 24"
                                            fill="none"
                                            stroke="currentColor"
                                            stroke-width="2"
                                            stroke-linecap="round"
                                            stroke-linejoin="round"
                                            class="feather feather-user"
                                        >
                                            <path d="M20 21v-2a4 4 0 0 0-4-4H8a4 4 0 0 0-4 4v2"></path>
                                            <circle cx="12" cy="7" r="4"></circle>
                                        </svg>
                                        <b-input placeholder="Email" v-model="user.email" :class="{ 'is-invalid': errors.email }"></b-input>
                                        <div class="invalid-feedback" v-if="errors.email">
                                          {{ errors.email[0] }}
                                        </div>
                                    </div>

                                    <div id="password-field" class="field-wrapper input mb-2">
                                        <svg
                                            xmlns="http://www.w3.org/2000/svg"
                                            width="24"
                                            height="24"
                                            viewBox="0 0 24 24"
                                            fill="none"
                                            stroke="currentColor"
                                            stroke-width="2"
                                            stroke-linecap="round"
                                            stroke-linejoin="round"
                                            class="feather feather-lock"
                                        >
                                            <rect x="3" y="11" width="18" height="11" rx="2" ry="2"></rect>
                                            <path d="M7 11V7a5 5 0 0 1 10 0v4"></path>
                                        </svg>
                                        <b-input :type="type" placeholder="Heslo" v-model="user.password" :class="{ 'is-invalid': errors.password }"></b-input>
                                        <div class="invalid-feedback" v-if="errors.password">
                                          {{ errors.password[0] }}
                                        </div>
                                    </div>
                                    <div class="d-sm-flex justify-content-between">
                                        <div class="field-wrapper toggle-pass d-flex align-items-center">
                                            <p class="d-inline-block">Ukázat heslo</p>
                                            <b-checkbox @change="showPassword" switch class="switch s-primary"></b-checkbox>
                                        </div>
                                        <div class="field-wrapper">
                                            <b-button type="submit" variant="primary" :disabled="!user.email || !user.password || submitAnimation">Přihlásit se</b-button>
                                        </div>
                                    </div>

                                </div>
                            </b-form>
                        </div>
                    </div>
                </div>
            </div>
            <div class="form-image">
                <div class="l-image"></div>
            </div>
        </div>
    </div>
</template>

<script>
    import '../../assets/sass/authentication/auth.scss';
    import { mapGetters, mapActions } from "vuex";
    import axios from "axios";

    export default {
        metaInfo: { title: 'Login Cover' },
        data() {
            return {
              user: {
                email: null,
                password: null
              },
              type: 'password',
              submitAnimation: false
            };
        },
        computed: {
          ...mapGetters(["errors"]),
        },

        mounted() {
          this.$store.commit("setErrors", {});
        },

        created() {
          document.querySelector('body').classList.add('auth');
        },
        destroyed () {
          document.body.classList.remove('auth')
        },

        methods: {
          ...mapActions("auth", ["sendLoginRequest"]),

          login() {
            axios.get('sanctum/csrf-cookie').then(()=>{
              this.sendLoginRequest(this.user)
            })
          },
          showPassword() {
            if(this.type === 'password') {
              this.type = 'text'
              this.btnText = 'Hide Password'
            } else {
              this.type = 'password'
              this.btnText = 'Show Password'
            }
          }
        }
    };
</script>

<style scoped lang="scss">
.btn {
    &:after {
        display: none;
    }
}
</style>
